import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/wizard',
        name: 'wizard',
        component: () => import('./components/wizard/WizardList')
    },
    {
        path: '/step/:stepId/question',
        name: 'step-questions',
        component: () => import('./components/step/question/QuestionList')
    },
    {
        path: '/wizard/:id/step',
        name: 'wizard-steps',
        component: () => import('./components/wizard/step/StepList')
    },
    {
        path: '/question',
        name: 'question',
        component: () => import('./components/question/QuestionList')
    },
    {
        path: '/step',
        name: 'step',
        component: () => import('./components/step/StepList')
    },
    {
        path: '/country',
        name: 'country',
        component: () => import('./components/country/CountryList')
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('./components/input-type/InputTypeList')
    },
    {
        path: '/ab-testing',
        name: 'ab-testing',
        component: () => import('./components/price-calculator/ab-testing/MarketTestingList')
    },
    {
        path: '/discount',
        name: 'discount',
        component: () => import('./components/price-calculator/discount/DiscountList')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access')
    },
    {
        path: '/customer',
        name: 'customer',
        component: () => import('./components/customer/CustomerList')
    },
    {
        path: '/submission',
        name: 'submission',
        component: () => import('./components/submission/SubmissionList')
    },
    {
        path: '/quote',
        name: 'quote',
        component: () => import('./components/quote/QuoteList')
    },
    {
        path: '/price-calculator',
        name: 'calculators',
        component: () => import('./components/price-calculator/PriceCalculatorList')
    },
    {
        path: '/price-calculator/:calculatorId/factor',
        name: 'factors',
        component: () => import('./components/price-calculator/factor/FactorList')
    },
    {
        path: '/price-calculator/:calculatorId/rider',
        name: 'riders',
        component: () => import('./components/price-calculator/rider/RiderList')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
